import React from "react"
import Layout from "../components/layout"

const Costumes = ({}) => {
  const removeLocalStorage = () => {
    localStorage.clear()
  }

  const setLocalStorage = () => {
    localStorage.setItem("internal", "true")
  }

  return (
    <Layout>
      <div className="container py-2 px-1 mx-auto min-h-full">
        <div className="min-h-screen mt-20">
          <div className="flex flex-col gap-10">
            <button
              className="bg-ascendance text-white px-5 py-10 rounded block text-center text-xl"
              onClick={setLocalStorage}
            >
              Set Local Storage
            </button>
            <button
              className="bg-ascendance text-white px-5 py-10 rounded block text-center text-xl"
              onClick={removeLocalStorage}
            >
              Remove Local Storage
            </button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Costumes
